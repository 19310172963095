import React from 'react'
import Layout from '../components/layout'

import styles from './location.module.scss'

const Location = ({
  pageContext: {
    fields: {
      title,
      description,
      phoneNumber,
      addressLine1,
      city,
      state,
      zipCode
    }
  }
}) => {
  return (
    <Layout title={title}>
      <article className={styles.article}>
        <h2>{title}</h2>
        <p><strong>Description:</strong></p>
        <p>{description}</p>
        <p><strong>Phone Number:</strong></p>
        <p>{phoneNumber}</p>
        <p><strong>Address:</strong></p>
        <p>{title}<br />{addressLine1}<br />{city}, {state} {zipCode}</p>
      </article>
    </Layout>
  )
}

export default Location
